// Generated by Framer (f92ac27)

import { addFonts, cx, CycleVariantState, Image, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion } from "framer-motion";
import * as React from "react";

const enabledGestures = {rAgKQn9Tv: {hover: true}};

const cycleOrder = ["rAgKQn9Tv"];

const variantClassNames = {rAgKQn9Tv: "framer-v-wkeqbu"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const humanReadableVariantMap = {};

const transitions = {default: {damping: 60, delay: 0, duration: 0.3, ease: [0.44, 0, 0.56, 1], mass: 1, stiffness: 500, type: "spring"}};

const BASE62 = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz"

function useRandomID(){const ref = React.useRef(null);
if (ref.current === null) {
ref.current = Array(5).fill(0).map(() => BASE62[Math.floor(Math.random() * BASE62.length)]).join("");
}
return ref.current;}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { layoutId?: string | null; width?: number; height?: number; layoutDependency?: string | number; variant?: keyof typeof humanReadableVariantMap; }

const Component = React.forwardRef<HTMLDivElement, Props>(function({ id, style: externalStyle = {}, className, width, height, layoutId, variant: outerVariant = "rAgKQn9Tv", ...restProps }, ref) {

const outerVariantId = humanReadableVariantMap[outerVariant];

const variant = outerVariantId || outerVariant;

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "rAgKQn9Tv", enabledGestures, transitions, variant, variantClassNames})
const layoutDependency = variants.join("-") + restProps.layoutDependency;

const defaultLayoutId = useRandomID()

const { pointerEvents, ...style } = externalStyle

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div data-framer-generated initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-7EQ8Q", classNames)} style={{display: "contents", pointerEvents: pointerEvents ?? "auto"}}>
<motion.div {...restProps} background={{alt: "Buddha charcoal art by Sona", fit: "fit", intrinsicHeight: 434, intrinsicWidth: 507, pixelHeight: 434, pixelWidth: 507, src: new URL("assets/512/CKb8WOMvPGFMi21rSNZ1cOdDo.jpg", import.meta.url).href, srcSet: `${new URL("assets/512/CKb8WOMvPGFMi21rSNZ1cOdDo.jpg", import.meta.url).href} 512w, ${new URL("assets/CKb8WOMvPGFMi21rSNZ1cOdDo.jpg", import.meta.url).href} 750w`}} className={cx("framer-wkeqbu", className)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"rAgKQn9Tv"} ref={ref} style={{borderBottomLeftRadius: 10, borderBottomRightRadius: 10, borderTopLeftRadius: 10, borderTopRightRadius: 10, filter: "grayscale(1)", WebkitFilter: "grayscale(1)", ...style}} transition={transition} variants={{"rAgKQn9Tv-hover": {filter: "grayscale(0)", WebkitFilter: "grayscale(0)"}}} {...addPropertyOverrides({"rAgKQn9Tv-hover": {"data-framer-name": undefined}}, baseVariant, gestureVariant)}><Image background={{alt: "Sona Work", fit: "fit", intrinsicHeight: 297, intrinsicWidth: 366, pixelHeight: 297, pixelWidth: 366, sizes: "340px", src: new URL("assets/512/h1wgUxCBngJO1eqA0MKwQcxqKOc.jpg", import.meta.url).href, srcSet: `${new URL("assets/512/h1wgUxCBngJO1eqA0MKwQcxqKOc.jpg", import.meta.url).href} 512w, ${new URL("assets/h1wgUxCBngJO1eqA0MKwQcxqKOc.jpg", import.meta.url).href} 750w`}} className={"framer-mejbuy"} data-framer-name={"image 21"} layoutDependency={layoutDependency} layoutId={"XKXEsBx3a"} transition={transition}/></motion.div>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-7EQ8Q [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-7EQ8Q * { box-sizing: border-box; }", ".framer-7EQ8Q .framer-1gfqkad { display: block; }", ".framer-7EQ8Q .framer-wkeqbu { height: 340px; overflow: hidden; position: relative; width: 340px; will-change: transform; }", ".framer-7EQ8Q .framer-mejbuy { aspect-ratio: 1 / 1; flex: none; height: var(--framer-aspect-ratio-supported, 340px); left: 0px; overflow: hidden; position: absolute; top: 0px; width: 340px; }", ".framer-7EQ8Q .framer-v-wkeqbu .framer-wkeqbu { cursor: pointer; }", ".framer-7EQ8Q.framer-v-wkeqbu.hover .framer-wkeqbu { aspect-ratio: 1 / 1; height: var(--framer-aspect-ratio-supported, 340px); }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 340
 * @framerIntrinsicWidth 340
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"W0XxPN2za":{"layout":["fixed","fixed"]}}}
 */
const FramersEjGuV0uJ: React.ComponentType<Props> = withCSS(Component, css) as typeof Component;
export default FramersEjGuV0uJ;

FramersEjGuV0uJ.displayName = "project-grayscale";

FramersEjGuV0uJ.defaultProps = {height: 340, width: 340};

addFonts(FramersEjGuV0uJ, [])